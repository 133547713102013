import { useQuery } from 'react-query';

import { PathSearchFilter, RatePath } from '../../models/Path';
import { Rate } from '../../models/Rate';
import pathService from '../../services/pathService';

export const useSearchPaths = (filter?: PathSearchFilter) => {
  const { data, isFetching, status } = useQuery<RatePath[][], Error>(
    ['paths', filter],
    () => pathService.getPaths(filter!),
    {
      enabled: !!filter,
      retry: false,
    }
  );

  return {
    isLoading: isFetching,
    paths: data || [],
    status,
  };
};

export const useNextRates = (location: string) => {
  const { data, isFetching, status } = useQuery<Rate[], Error>(
    ['rates', location],
    () => pathService.getNextRates(location!),
    {
      enabled: !!location,
      retry: false,
    }
  );

  return {
    rates: data || [],
    searchingRates: isFetching,
    status,
  };
};
