import { Button, Popconfirm, Table } from 'antd';
import { useState } from 'react';

import { tripTotalDistance, tripTotalTransitDays } from '../../models/Trip';

const TripDisplay = ({ onDelete, trip }) => {
  const [expanded, setExpanded] = useState(false);

  const handleDelete = (tripId) => {
    setExpanded(false);
    onDelete(tripId);
  };

  const columns = [
    { dataIndex: 'origin', key: 'origin', title: 'Origin' },
    {
      dataIndex: 'destination',
      key: 'destination',
      title: 'Destination',
    },
    {
      dataIndex: 'carrierName',
      key: 'carrierName',
      title: 'Carrier',
    },
    { dataIndex: 'modeName', key: 'modeName', title: 'Mode' },
    { dataIndex: 'distance', key: 'distance', title: 'Distance' },
    {
      dataIndex: 'transitDays',
      key: 'transitDays',
      title: 'Transit Days',
    },
    {
      dataIndex: 'preferred',
      key: 'preferred',
      render: (preferred) => (preferred ? 'Yes' : 'No'),
      title: 'Preferred',
    },
  ];

  const data = trip.rate_trips.map((nextRateTrip) => {
    return { ...nextRateTrip.rate, key: nextRateTrip.id };
  });

  const carriersList = () => {
    const carriers = trip.rate_trips.map(
      (nextRateTrip) => nextRateTrip.rate.carrierName
    );
    const carriersSet = new Set(carriers);
    const sortedCarriers = Array.from(carriersSet).sort();
    return sortedCarriers.join(', ');
  };

  if (!trip) {
    return null;
  }

  return (
    <>
      <div
        key={trip.id}
        style={{
          borderTop: '1px solid #ccc',
          display: 'flex',
          justifyContent: 'space-between',
          margin: 12,
          padding: 12,
        }}
      >
        <div style={{ flex: 1, fontWeight: 'bold' }}>
          {trip.origin} - {trip.destination}
        </div>
        <div style={{ flex: 1 }}>{carriersList()}</div>
        <div style={{ flex: 1 }}>
          <b>Total Distance:</b> {Math.round(tripTotalDistance(trip))}km
        </div>
        <div style={{ flex: 1 }}>
          <b>Transit Days:</b>
          {tripTotalTransitDays(trip)}
        </div>
        <div>
          <Button onClick={() => setExpanded(!expanded)}>
            {expanded ? 'Collapse' : 'Expand'}
          </Button>
        </div>
      </div>

      {expanded && (
        <div>
          <Table columns={columns} dataSource={data} pagination={false} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 12,
            }}
          >
            <Popconfirm
              cancelText="No"
              okText="Yes"
              title="Are you sure you want to delete this trip?"
              onConfirm={() => handleDelete(trip.id)}
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          </div>
        </div>
      )}
    </>
  );
};

export default TripDisplay;
