import './TripsPage.less';

import { PageHeader } from '@ant-design/pro-layout';
import { Card, Space } from 'antd';

import TripDisplay from '../components/common/TripDisplay';
import { useTripDelete, useTrips } from '../hooks/apiHooks/trip.';
import Layout from '../layout';

const TripsPage = ({ routes }) => {
  const { loadingTrips, refetch: refetchTrips, trips } = useTrips();
  const { mutate: deleteTrip } = useTripDelete();
  //console.log(trips);

  const tripDeleted = (id) => {
    deleteTrip(id, {
      onError: (error) => {
        console.error('Error deleting trip: ', error);
      },
      onSuccess: () => {
        console.log('Trip deleted!');
        refetchTrips();
      },
    });
  };

  return (
    <Layout disableLoading showCarriersNavigation menuIndex={0} routes={routes}>
      <PageHeader className="site-page-header" title="Trips" />

      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Card>
          {loadingTrips && <p>Loading trips...</p>}
          {trips && trips.length > 0 && (
            <>
              {trips.map((nextTrip, i) => (
                <TripDisplay key={i} trip={nextTrip} onDelete={tripDeleted} />
              ))}
            </>
          )}
        </Card>
      </Space>
    </Layout>
  );
};

export default TripsPage;
