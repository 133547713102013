import { Rate } from './Rate';

export type RateTrip = {
  rateId: Number;
  tripId: Number;
  rate: Rate;
  trip: Trip;
  position: Number;
};

export type Trip = {
  id: Number;
  rate_trips: RateTrip[];
  origin: string;
  destination: string;
  notes: string;
  transitDays?: number;
  preferred: boolean;
};

export const tripTotalDistance = (trip: Trip) => {
  return trip.rate_trips.reduce(
    (total, ratetrip) => total + (ratetrip.rate.distance || 0),
    0
  );
};

export const tripTotalTransitDays = (trip: Trip) => {
  return trip.rate_trips.reduce(
    (total, ratetrip) => total + (ratetrip.rate.transitDays || 0),
    0
  );
};
