import { useMutation, useQuery, useQueryClient } from 'react-query';

import tripService from '../../services/tripService';

export const useTrips = () => {
  const { data, isFetching, refetch, status } = useQuery(
    ['trips'],
    () => tripService.getTrips(),
    {
      enabled: true,
      retry: false,
    }
  );

  return {
    loadingTrips: isFetching,
    refetch,
    status,
    trips: data || [],
  };
};

export const useTripCreate = (rateIds) => {
  console.log('Running mutation with rateIds: ', rateIds);
  const queryClient = useQueryClient();
  return useMutation(tripService.create, {
    onSuccess: (data) => {
      queryClient.setQueryData('trip', { rates: rateIds }, data);
    },
  });
};

export const useTripDelete = (tripid) => {
  const queryClient = useQueryClient();
  return useMutation(tripService.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries('trips');
    },
  });
};
