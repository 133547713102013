import { PageHeader } from '@ant-design/pro-layout';
import { Space } from 'antd';
import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';

import PathFilter from '../components/filters/PathFilter';
import PathTable from '../components/tables/PathTable';
import Layout from '../layout';

const ShortestPathSearchPage = ({ routes }) => {
  const [filter, setFilter] = useState();
  const queryClient = useQueryClient();

  const onFilterChange = useCallback(
    (newFilter) => {
      queryClient.invalidateQueries(['paths', filter]);
      setFilter(newFilter);
    },
    [filter, queryClient]
  );

  return (
    <Layout disableLoading showCarriersNavigation menuIndex={0} routes={routes}>
      <PageHeader className="site-page-header" title="All Carriers" />

      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <PathFilter
          filter={filter}
          onFilterChange={onFilterChange}
        />

        {filter && filter.origin && filter.destination && (
          <>
            <PathTable filter={filter} searchType="enclosed" title="Enclosed Truck Only" />
            <PathTable filter={filter} searchType="closest" title="Closest Preferred Trips" />
            <PathTable filter={filter} searchType="cheapest" title="Cheapest Preferred Trips" />
            <PathTable filter={filter} searchType="personal" title="Personal Items Allowed" />
          </>
        )}
        {!(filter && filter.origin && filter.destination) && (
          <div><p style={{fontSize: '18px'}}>Enter search details to gather rates.</p></div>
        )}
      </Space>
    </Layout>
  );
};

export default ShortestPathSearchPage;
