import axios from 'axios';

import { Trip } from '../models/Trip';

const uri = process.env.REACT_APP_ALT_API_URL + '/trips';

const getTrips = async () => {
  const { data } = await axios.get<Trip[]>(uri);
  return data;
};

const create = async (rateIds: Number[]) => {
  const { data } = await axios.post(`${uri}`, rateIds);
  return data;
};

const remove = async (id: number) => {
  const { data } = await axios.delete(`${uri}/${id}`);
  return data;
};

const apis = {
  create,
  getTrips,
  remove,
};

export default apis;
