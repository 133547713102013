/* eslint-disable no-unused-vars */

export type Rating = {
  id: number;
  carrierId: number;
  damageClaims: number;
  reliability: number;
  pastPerformance: number;
  cost: number;
  honesty: number;
  carrierTier: string;
};

export enum CarrierTier {
  PREFERRED = 'preferred',
  SECOND = 'second',
  THIRD = 'third',
}
