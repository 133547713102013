import axios from 'axios';

import { PathSearchFilter, RatePath } from '../models/Path';
import { Rate } from '../models/Rate';

const uri = process.env.REACT_APP_ALT_API_URL + '/paths/search';
// const uri = '/path';

// for the path search
const getPaths = async (filter: PathSearchFilter) => {
  const params = new URLSearchParams();
  params.append('origin', filter.origin);
  params.append('destination', filter.destination);
  params.append('radius', filter.radius?.toString() || '');
  params.append('t', filter.t || '');

  const { data } = await axios.get<RatePath[][]>(`${uri}?${params.toString()}`);
  return data;
};

// for the dynamic path builder
const getNextRates = async (location: string) => {
  const params = new URLSearchParams();
  params.append('location', location);
  const { data } = await axios.get<Rate[]>(`/path/next?${params.toString()}`);

  return data;
};

const apis = {
  getNextRates,
  getPaths,
};

export default apis;
