/* eslint-disable react-hooks/exhaustive-deps */
import './PathTable.less';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Spin, Tag, Tooltip } from 'antd';
import axios from 'axios';
import React, { Fragment, useEffect } from 'react';

import { PathSearchFilter, RatePath } from '../../models/Path';
import pathService from '../../services/pathService';

interface IPathTableProps {
  filter: PathSearchFilter;
  searchType: string;
  title: String;  
}

const getFieldValue = (value?: string) => {
  return value ? value : <>&mdash;&mdash;&mdash;&mdash;</>;
};

const savePath = async (path: RatePath[]) => {
  //console.log('Save Path', path);
  // get rateIds from each member of path, skipping the ones without a rateId
  const rateIds = path.map((p) => p.rateId).filter((r) => r);
  //console.log('Rate Ids', rateIds);

  // queryClient and all that is a hot mess and doesn't want to work here for convoluted reasons so we're just calling Axios directly for now
  // JS doesn't think that this is a react component so it won't let us use hooks here
  const result = await axios.post(
    process.env.REACT_APP_ALT_API_URL + '/trips',
    { rateIds: rateIds }
  );
  if (result.status !== 201) {
    console.error('Failed to save trip', result);
    return;
  } else {
    alert('Added trip to your list');
  }
};

const PathTable: React.FC<IPathTableProps> = ({ filter, searchType, title }) => {  
  const [isLoading, setIsLoading] = React.useState(false);
  const [paths, setPaths] = React.useState<RatePath[][]>([]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const runSearch = async () => {
    setPaths([]);
    setIsLoading(true);
    
    const newFilter = { ...filter, t: searchType };
    const paths = await pathService.getPaths(newFilter);

    setPaths(paths);
    setIsLoading(false);
  }

  useEffect(() => {
    // on filter or searchtype change run the search with appropriate parameters
    runSearch();
  }, [filter, searchType]);

  return (
    <Card>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <div style={{flex: 1}}>
          <h3>{title}</h3>
        </div>
        <div style={{flex: 1, textAlign: 'right'}}>
          {paths.length > 0 && <h3>{paths.length} Path{paths.length > 1 ? 's' : ''} Found</h3>}
        </div>
      </div>
      {isLoading && <Spin size="large" style={{width: '100%'}} />}
      {paths.length > 0 && (
      <table className="path-table">
        <thead>
          <tr>
            <th></th>
            <th>Origin</th>
            <th>Org Type</th>
            <th>Destination</th>
            <th>Dest Type</th>
            <th>Mode</th>
            <th>Items</th>
            <th>Distance (kms)</th>
            <th>Transit</th>
            <th className="text-center">Rate</th>
          </tr>
        </thead>
        {paths.map((path, index) => (
          <>
            <Fragment key={index}>
              <tbody className="path-table__body">
                {path.map((ratePath, ratePathIndex) => (
                  <tr
                    key={ratePathIndex}
                    className={ratePath.preferred ? 'preferred' : ''}
                  >
                    <td>
                      {ratePathIndex === 0
                        ? ratePath.carriers.join(' > ')
                        : ratePath.carrier}
                    </td>
                    <td>{getFieldValue(ratePath.origin)}</td>
                    <td>{getFieldValue(ratePath.shipType)}</td>
                    <td>{getFieldValue(ratePath.destination)}</td>
                    <td>{getFieldValue(ratePath.destinationShipType)}</td>
                    <td>
                      {ratePathIndex === 0
                        ? getFieldValue(ratePath.modes.join(', '))
                        : getFieldValue(ratePath.mode)}
                    </td>
                    <td>{getFieldValue(ratePath.items)}</td>
                    <td>
                      {getFieldValue(
                        ratePath.distance
                          ? Math.ceil(ratePath.distance).toLocaleString()
                          : undefined
                      )}
                    </td>
                    <td>
                      {getFieldValue(
                        ratePath.transitDays
                          ? ratePath.transitDays + ' days'
                          : undefined
                      )}
                    </td>
                    <td className="text-center">
                      {ratePath.rate ? (
                        <Tooltip title={ratePath.originalRate?.toFixed(2)}>
                          {ratePathIndex === 0 ? (
                            <Tag color="#358F56">{ratePath.rate.toFixed(2)}</Tag>
                          ) : (
                            ratePath.rate.toFixed(2)
                          )}
                        </Tooltip>
                      ) : (
                        <>&mdash;&mdash;&mdash;&mdash;</>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tbody className="path-table__separator">
                <tr>
                  <td colSpan={8}></td>
                </tr>
              </tbody>
              <div style={{ marginBottom: 20, width: '100%' }}>
                <Button
                  icon={<PlusCircleOutlined />}
                  size="small"
                  type="primary"
                  onClick={() => savePath(path)}
                >
                  Save as Trip
                </Button>
              </div>
            </Fragment>
          </>
        ))}
      </table>
      )}
    </Card>
  );
};

export default PathTable;
