import {
  AppstoreAddOutlined,
  ContactsOutlined,
  NodeIndexOutlined,
  SearchOutlined,
  SettingOutlined,
  SisternodeOutlined,
} from '@ant-design/icons';
// @todo Winner - Not sure if this is correct?
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { UserRole } from './models/User';
import AdminRatesPage from './pages/AdminRatesPage';
import AdminSettingsPage from './pages/AdminSettingsPage';
import AdminUploadPage from './pages/AdminUploadPage';
import CarrierRatesPage from './pages/CarrierRatesPage';
import CarrierSettingsPage from './pages/CarrierSettingsPage';
import CarrierUploadPage from './pages/CarrierUploadPage';
import ContactsPage from './pages/ContactsPage';
import PathBuilderPage from './pages/PathBuilderPage';
import ShortestPathSearchPage from './pages/ShortestPathSearchPage';
import TripsPage from './pages/TripsPage';

/*
export interface IPageProps {
  routes: IPageRoute[];
}

export interface IPageRoute extends LayoutRoute {
  component: React.FC<IPageProps>;
  menuIndex?: number;
}
*/

export const allRoutes = {
  [UserRole.SUPER_ADMIN]: [
    {
      component: AdminUploadPage,
      exact: true,
      hideInMenu: true,
      key: 'upload',
      menuIndex: 0,
      name: 'Upload Rates',
      path: '/upload',
    },
    {
      component: AdminRatesPage,
      exact: true,
      icon: <SisternodeOutlined />,
      menuIndex: 0,
      name: 'Rates',
      path: '/',
    },
    {
      component: ShortestPathSearchPage,
      exact: true,
      icon: <SearchOutlined />,
      menuIndex: 0,
      name: 'Search Path',
      path: '/search',
    },
    {
      component: PathBuilderPage,
      exact: true,
      icon: <AppstoreAddOutlined />,
      menuIndex: 0,
      name: 'Path Builder',
      path: '/pathBuilder',
    },
    {
      component: TripsPage,
      exact: true,
      icon: <NodeIndexOutlined />,
      menuIndex: 0,
      name: 'Trips',
      path: '/trips',
    },
    {
      component: ContactsPage,
      exact: true,
      icon: <ContactsOutlined />,
      menuIndex: 0,
      name: 'Contacts',
      path: '/contacts',
    },
    {
      component: AdminSettingsPage,
      exact: true,
      icon: <SettingOutlined />,
      key: 'settings',
      menuIndex: 0,
      name: 'Settings',
      path: '/settings',
      routes: [
        {
          key: 'users',
          name: 'Users',
          path: '/settings#users',
        },
        {
          key: 'carriers',
          name: 'Carriers',
          path: '/settings#carriers',
        },
        {
          key: 'terminals',
          name: 'Terminals',
          path: '/settings#terminals',
        },
        {
          key: 'modes',
          name: 'Modes of Transport',
          path: '/settings#modes',
        },
        {
          key: 'vehicles',
          name: 'Vehicle Types',
          path: '/settings#vehicles',
        },
        {
          key: 'shipTypes',
          name: 'Ship Types',
          path: '/settings#shipTypes',
        },
        {
          key: 'password',
          name: 'Update Password',
          path: '/settings#password',
        },
      ],
    },
    {
      component: CarrierRatesPage,
      exact: true,
      icon: <SisternodeOutlined />,
      menuIndex: 1,
      name: 'Rates',
      path: '/carrier/:carrierId/',
    },
    {
      component: CarrierSettingsPage,
      exact: true,
      icon: <SettingOutlined />,
      key: 'settings',
      menuIndex: 1,
      name: 'Settings',
      path: '/carrier/:carrierId/settings/',
      routes: [
        {
          key: 'profile',
          name: 'Profile',
          path: '/carrier/:carrierId/settings#profile',
        },
        {
          key: 'modes',
          name: 'Modes of Transport',
          path: '/carrier/:carrierId/settings#modes',
        },
        {
          key: 'classes',
          name: 'Rate Class',
          path: '/carrier/:carrierId/settings#rate-class',
        },
        {
          key: 'vehicles',
          name: 'Vehicle Types',
          path: '/carrier/:carrierId/settings#vehicles',
        },
        {
          key: 'options',
          name: 'Options',
          path: '/carrier/:carrierId/settings#options',
        },
        {
          key: 'terminals',
          name: 'Terminals Serviced',
          path: '/carrier/:carrierId/settings#terminals',
        },
        {
          key: 'insurance',
          name: 'Insurance',
          path: '/carrier/:carrierId/settings#insurance',
        },
        {
          key: 'ratings',
          name: 'Ratings',
          path: '/carrier/:carrierId/settings#ratings',
        },
        {
          key: 'notes',
          name: 'Notes',
          path: '/carrier/:carrierId/settings#notes',
        },
      ],
    },
    {
      component: CarrierUploadPage,

      hideInMenu: true,
      key: 'upload',
      menuIndex: 1,
      name: 'Upload Rates',
      path: '/carrier/:carrierId/upload',
    },
  ],
  [UserRole.ADMIN]: [
    {
      component: AdminUploadPage,
      exact: true,
      hideInMenu: true,
      key: 'upload',
      menuIndex: 0,
      name: 'Upload Rates',
      path: '/upload',
    },
    {
      component: AdminRatesPage,
      exact: true,
      icon: <SisternodeOutlined />,
      menuIndex: 0,
      name: 'Rates',
      path: '/',
    },
    {
      component: CarrierRatesPage,
      exact: true,
      icon: <SisternodeOutlined />,
      menuIndex: 1,
      name: 'Rates',
      path: '/carrier/:carrierId/',
    },
    {
      component: CarrierSettingsPage,
      exact: true,
      icon: <SettingOutlined />,
      key: 'settings',
      menuIndex: 1,
      name: 'Settings',
      path: '/carrier/:carrierId/settings/',
      routes: [
        {
          key: 'profile',
          name: 'Profile',
          path: '/carrier/:carrierId/settings#profile',
        },
        {
          key: 'modes',
          name: 'Modes of Transport',
          path: '/carrier/:carrierId/settings#modes',
        },
        {
          key: 'classes',
          name: 'Rate Class',
          path: '/carrier/:carrierId/settings#rate-class',
        },
        {
          key: 'vehicles',
          name: 'Vehicle Types',
          path: '/carrier/:carrierId/settings#vehicles',
        },
        {
          key: 'options',
          name: 'Options',
          path: '/carrier/:carrierId/settings#options',
        },
        {
          key: 'terminals',
          name: 'Terminals Serviced',
          path: '/carrier/:carrierId/settings#terminals',
        },
        {
          key: 'insurance',
          name: 'Insurance',
          path: '/carrier/:carrierId/settings#insurance',
        },
        {
          key: 'ratings',
          name: 'Ratings',
          path: '/carrier/:carrierId/settings#ratings',
        },
        {
          key: 'notes',
          name: 'Notes',
          path: '/carrier/:carrierId/settings#notes',
        },
      ],
    },
    {
      component: CarrierUploadPage,

      hideInMenu: true,
      key: 'upload',
      menuIndex: 1,
      name: 'Upload Rates',
      path: '/carrier/:carrierId/upload',
    },
  ],
  [UserRole.CARRIER]: [
    {
      component: CarrierUploadPage,

      exact: true,
      hideInMenu: true,
      key: 'upload',
      menuIndex: 0,
      name: 'Upload Rates',
      path: '/upload',
    },
    {
      component: CarrierRatesPage,

      exact: true,
      icon: <SisternodeOutlined />,
      menuIndex: 0,
      name: 'Rates',
      path: '/',
    },
    {
      component: CarrierSettingsPage,

      exact: true,
      icon: <SettingOutlined />,
      key: 'settings',
      menuIndex: 0,
      name: 'Settings',
      path: '/settings/',
      routes: [
        {
          key: 'profile',
          name: 'Profile',
          path: '/settings#profile',
        },
        {
          key: 'modes',
          name: 'Modes of Transport',
          path: '/settings#modes',
        },
        {
          key: 'classes',
          name: 'Rate Class',
          path: '/settings#rate-class',
        },
        {
          key: 'vehicles',
          name: 'Vehicle Types',
          path: '/settings#vehicles',
        },
        {
          key: 'options',
          name: 'Options',
          path: '/settings#options',
        },
        {
          key: 'terminals',
          name: 'Terminals Serviced',
          path: '/settings#terminals',
        },
        {
          key: 'insurance',
          name: 'Insurance',
          path: '/settings#insurance',
        },
        {
          key: 'user',
          name: 'Update User',
          path: '/settings#user',
        },
      ],
    },
  ],
  [UserRole.CUSTOMER]: [
    {
      component: CarrierRatesPage,
      exact: true,
      icon: <SisternodeOutlined />,
      menuIndex: 0,
      name: 'Rates',
      path: '/',
    },
  ],
};

/*
interface IProps {
  routes: IPageRoute[];
}
*/

const AppRoutes = ({ routes }) => {
  return (
    <Switch>
      {routes.map((route) => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          render={() => <route.component routes={routes} />}
        />
      ))}
    </Switch>
  );
};

export default AppRoutes;
